import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'fully_stocked',
  formId: 'DK%20Fully%20Stocked',
  title: 'Consumables',
  subTitle: 'From rosé to toilet paper... You’ll find it right here!',
  icon: '/icons/consumables.svg',
  image: '/assets/images/sunrise/consumables_supermercat.png',
  theme: 'sunrise-consumables',
  sections: [
    {
      sectionId: 'hero',
      label: 'Consumables',
      title: 'From rosé to toilet paper...',
      description:
        'Running out of stock at the office is never fun... especially not when it comes to toilet paper! We’ve gathered all the key items you need at your workplace to make sure you’re always on top of your game.',
      image: '/assets/images/sunrise/consumables_supermercat.png',
      icon: '/icons/consumables.svg',
      buttonText: 'Request a proposal',
    },
    {
      sectionId: 'options',
      title: 'Everything to keep the office running',
      description:
        'People often think that an office by default is fully stocked. But it’s not. We’ll help you find and order everything you need!',
      optionsItems: [
        {
          icon: '/assets/images/services/consumables/service_1.svg',
          title: 'Office supplies',
          description: 'Post-it notes, paper, pens, printer ink, highlighters etc. You name it - we got it.',
        },
        {
          icon: '/assets/images/services/consumables/service_2.svg',
          title: 'Hygiene products',
          description:
            'It seems like hygiene is a ‘real thing’ now even though it always has been. Get sanitiser and everything else you need to stay healthy',
        },
        {
          icon: '/assets/images/services/consumables/service_3.svg',
          title: 'Coffee, tea and all other beverages',
          description:
            'We’ll fill up on all drinks to make sure the office is never thirsty. Dehydration has never been cool.',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Just a few steps and you’re fully stocked',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/consumables/journey_1.svg',
          title: 'Product and price overview',
          description: 'We’ll send a full product and price overview - no fine print.',
        },
        {
          icon: '/assets/images/services/consumables/journey_2.svg',
          title: 'Quick office inspection',
          description: 'We’ll send an office assistant to inspect all your needs',
        },
        {
          icon: '/assets/images/services/consumables/journey_3.svg',
          title: 'Get started',
          description: 'Now you’re fully stocked... FOR LIFE! How awesome is that!',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Why Consumables through Good Monday is ideal for companies',
      description: '',
      image: '/assets/images/services/consumables/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-layers-outline',
          title: 'Never run out of stock',
          description: 'We can make sure that you never (ever!) run out of the daily things you use.',
        },
        {
          icon: 'eva eva-collapse-outline',
          title: 'Have everything in one place',
          description: 'You’ll have a full overview of everything you consume at the office.',
        },
        {
          icon: 'eva eva-pricetags-outline',
          title: 'Get the best prices',
          description: 'We are the procurement department you searched for and we’ll negotiate on your behalf.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Did you know that with Good Monday’s ‘fully stocked’ you’ll never run out of toilet rolls again?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Request a proposal',
    },
  ],
}
